
























































import Vue from 'vue'
import { mapMutations } from 'vuex'
import UserService from '@/services/user'

export default Vue.extend({
  props: {
    value: {
      type: Boolean
    }
  },

  data(): {
    isGenerateModalOpen: boolean
    isRemoveModalOpen: boolean
    qrImage?: string
    otp?: string
    removeOTP?: string
    isLoading: boolean
  } {
    return {
      isGenerateModalOpen: false,
      isRemoveModalOpen: false,
      qrImage: undefined,
      otp: undefined,
      removeOTP: undefined,
      isLoading: false
    }
  },

  methods: {
    ...mapMutations('auth', ['UPDATE_CURRENT_USER']),

    ...mapMutations('message', ['SET_MESSAGE']),

    toggle2FA() {
      if (this.value) {
        this.isRemoveModalOpen = true
      } else {
        this.generate2FA()
      }
    },

    async disable2FA() {
      if (!this.removeOTP) return
      this.isLoading = true

      try {
        const { data: removeOtpRes } = await UserService.removeOtp({ otp: this.removeOTP })
        if (removeOtpRes.success) {
          this.UPDATE_CURRENT_USER({ security: { otp: false } })
          this.SET_MESSAGE({
            type: 'success',
            message: '2FA has been disabled'
          })
        }
      } finally {
        this.isLoading = false
      }
    },

    async generate2FA() {
      this.isLoading = true

      try {
        const { data: otpRes } = await UserService.generateOtpQRCode()
        if (otpRes.success) {
          this.qrImage = otpRes.data
          this.isGenerateModalOpen = true
        }
      } finally {
        this.isLoading = false
      }
    },

    async addOtp() {
      if (!this.otp) return

      this.isLoading = true

      try {
        const { data: addOtpRes } = await UserService.addOtp({ otp: this.otp })
        if (addOtpRes.success) {
          this.UPDATE_CURRENT_USER({ security: { otp: true } })
          this.isGenerateModalOpen = false
          this.SET_MESSAGE({
            type: 'success',
            message: '2FA has been enabled'
          })
        }
      } finally {
        this.isLoading = false
      }
    },

    closeModal() {
      this.isGenerateModalOpen = false
      this.isRemoveModalOpen = false
      this.qrImage = undefined
    }

    // b64ToBlob(b64Data: string, contentType = 'image/png') {
    //   const sliceSize = 512
    //   const byteCharacters = atob(b64Data)
    //   const byteArrays = []

    //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    //     const slice = byteCharacters.slice(offset, offset + sliceSize)
    //     const byteNumbers = new Array(slice.length)

    //     for (let i = 0; i < slice.length; i++) {
    //       byteNumbers[i] = slice.charCodeAt(i)
    //     }

    //     const byteArray = new Uint8Array(byteNumbers)
    //     byteArrays.push(byteArray)
    //   }

    //   return new Blob(byteArrays, { type: contentType })
    // }
  }
})
